/* RESPONSIBLE TEAM: team-tickets-1 */
import { inject as service } from '@ember/service';
import ProductSettingsRoute from 'embercom/routes/apps/app/settings/base/product-settings-route';
import RSVP from 'rsvp';

export default ProductSettingsRoute.extend({
  store: service(),
  analytics: {
    section: 'settings',
    place: 'slas',
  },
  titleToken: 'SLAs',
  beforeModel(transition) {
    this._super(...arguments);
    let app = this.modelFor('apps.app');
    if (!app.get('canUseInboxSlas')) {
      this.transitionTo('apps.app.settings.workspace.general');
    }
  },

  model() {
    return RSVP.hash({
      officeHoursSchedules: this.store.findAll('office-hours-schedule'),
      slas: this.store.findAll('inbox/conversation-sla'),
    });
  },
});
