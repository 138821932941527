/* RESPONSIBLE TEAM: team-messenger */
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type OfficeHoursSchedule from 'embercom/models/office-hours-schedule';
import ProductSettingsRoute from 'embercom/routes/apps/app/settings/base/product-settings-route';

export default class OfficeHoursRoute extends ProductSettingsRoute {
  @service store: any;
  @service appService: any;
  @service declare intl: IntlService;

  analytics = {
    section: 'new_settings',
    place: 'office_hours',
  };

  get titleToken() {
    return this.intl.t(`new-settings.submenu.workspace.office-hours`);
  }

  async model(): Promise<{
    schedules: OfficeHoursSchedule[];
    settings: any;
  }> {
    return {
      schedules: await this.store.findAll('office-hours-schedule', { reload: true }),
      settings: await this.store.findRecord(
        'messenger-settings/availability',
        this.appService.app.id,
      ),
    };
  }
}
