/* RESPONSIBLE TEAM: team-ai-agent */

import FinAIAgentCustomAnswersAnswerShowRoute from 'embercom/routes/apps/app/fin-ai-agent/custom-answers/answer/show';

export default class ResolutionBotAnswersAnswerShowRoute extends FinAIAgentCustomAnswersAnswerShowRoute {
  analytics = {
    section: 'resolution-bot',
    place: 'answer-show',
  };

  controllerName = 'apps.app.fin-ai-agent.custom-answers.answer.show';
  templateName = 'apps.app.fin-ai-agent.custom-answers.answer.show';
}
