/* RESPONSIBLE TEAM: team-ai-agent */

import FinAIAgentCustomAnswersIndexRoute from 'embercom/routes/apps/app/fin-ai-agent/custom-answers/index';

export default class ResolutionBotAnswersIndexRoute extends FinAIAgentCustomAnswersIndexRoute {
  analytics = {
    section: 'resolution-bot',
    place: 'answers_index_page',
  };

  templateName = 'apps.app.fin-ai-agent.custom-answers.index';
  controllerName = 'apps.app.fin-ai-agent.custom-answers.index';
}
