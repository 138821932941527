/* RESPONSIBLE TEAM: team-purchase-experience */

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { inject as service } from '@ember/service';
import { BILLING_PERIODS, PRICING_5_X_EARLY_STAGE_FREE_SOLUTION_ID } from 'embercom/lib/billing';
import moment from 'moment-timezone';

export enum CheckoutType {
  EarlyStagePostPurchase = 'early_stage_post_purchase',
  EarlyStageFreePostPurchase = 'early_stage_free_post_purchase',
  ACHPostPurchase = 'ach_post_purchase',
  ACHPostAnnualMigration = 'ach_post_annual_migration',
  DirectCheckoutPostPurchase = 'direct_checkout_post_purchase',
  DirectCheckoutPostAnnualMigration = 'direct_checkout_post_annual_migration',
  OneDollarTrialPostStart = 'one_dollar_trial_post_start',
}

export const DEFAULT_CHECKOUT_TYPE = CheckoutType.DirectCheckoutPostAnnualMigration;

export default class Success extends Route {
  @service declare appService: $TSFixMe;
  @service declare router: RouterService;
  @service declare customerService: $TSFixMe;

  isBillingPeriodMigration = false;

  queryParams = {
    isBillingPeriodMigration: {
      refreshModel: false,
      replace: true,
    },
  };

  async beforeModel(transition?: Transition | undefined) {
    if (!this.appService.app.canUsePostCheckoutSuccessScreen) {
      this.router.transitionTo('apps.app.index');
    }
    await this.appService.app.reload();
    await this.customerService.resetAll();
    await this.customerService.refreshCustomer();

    if (!this.checkoutType || !this.billingPeriod || !this.firstInvoicedOnDate) {
      this.router.transitionTo('apps.app.index');
    }
    this.isBillingPeriodMigration =
      transition?.to?.queryParams?.isBillingPeriodMigration === 'true';
  }

  async model() {
    return {
      checkoutType: this.checkoutType,
      planId: this.planId,
      firstInvoicedOnDate: this.firstInvoicedOnDate,
      billingPeriod: this.billingPeriod,
    };
  }

  get checkoutType(): CheckoutType {
    return Object.values(CheckoutType).find(this.isCheckoutType, this) ?? DEFAULT_CHECKOUT_TYPE;
  }

  get planId() {
    let { activePricing5CorePlanId } = this.customerService;
    return activePricing5CorePlanId ? String(activePricing5CorePlanId) : undefined; // activePricing5CorePlanId is undefined for customers on trial
  }

  get firstInvoicedOnDate() {
    return moment()
      .set('date', this.customerService.customer.subscription.invoiceDayOfMonth) // invoiceDayOfMonth and moment date are both 1-indexed
      .set('month', this.customerService.customer.subscription.initialInvoiceMonthOfYear - 1); // initialInvoiceMonthOfYear is 1-indexed, moment month is 0-indexed
  }

  get billingPeriod() {
    if (this.customerService.customer.subscription.isBilledAnnually) {
      return BILLING_PERIODS.Annual;
    }
    return BILLING_PERIODS.Monthly;
  }

  private get currentlyOnEarlyStage() {
    return !!this.customerService.customer.currentlyOnEarlyStage;
  }

  private get hasEarlyStageFreeSolutionId() {
    return (
      String(this.customerService.customer.earlyStageApplicationSolutionId) ===
      PRICING_5_X_EARLY_STAGE_FREE_SOLUTION_ID
    );
  }

  private isCheckoutType(checkoutType: CheckoutType) {
    return this.checkoutTypeCriteria[checkoutType];
  }

  private get checkoutTypeCriteria(): Record<CheckoutType, boolean> {
    return {
      [CheckoutType.EarlyStagePostPurchase]: this.isEarlyStagePostPurchase,
      [CheckoutType.EarlyStageFreePostPurchase]: this.isEarlyStageFreePostPurchase,
      [CheckoutType.ACHPostPurchase]: this.isACHPostPurchase,
      [CheckoutType.ACHPostAnnualMigration]: this.isACHPostAnnualMigration,
      [CheckoutType.DirectCheckoutPostPurchase]: this.isDirectCheckoutPostPurchase,
      [CheckoutType.DirectCheckoutPostAnnualMigration]: this.isDirectCheckoutPostAnnualMigration,
      [CheckoutType.OneDollarTrialPostStart]: this.isOneDollarTrialPostStart,
    };
  }

  private get isEarlyStagePostPurchase() {
    return this.currentlyOnEarlyStage && !this.hasEarlyStageFreeSolutionId;
  }

  private get isEarlyStageFreePostPurchase() {
    return this.currentlyOnEarlyStage && this.hasEarlyStageFreeSolutionId;
  }

  private get isACHPostPurchase() {
    return this.isACH && !this.isBillingPeriodMigration;
  }

  private get isACHPostAnnualMigration() {
    return this.isACH && this.isBillingPeriodMigration;
  }

  private get isACH() {
    return false;
  }

  private get isDirectCheckoutPostAnnualMigration() {
    return this.isDirectCheckout && this.isBillingPeriodMigration;
  }

  private get isDirectCheckoutPostPurchase() {
    return this.isDirectCheckout && !this.isBillingPeriodMigration;
  }

  private get isDirectCheckout() {
    return !this.isEarlyStagePostPurchase && !this.isOneDollarTrialPostStart && !this.isACH;
  }

  private get isOneDollarTrialPostStart() {
    return false;
  }
}
