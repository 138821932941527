/* RESPONSIBLE TEAM: team-standalone */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { taskFor } from 'ember-concurrency-ts';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';

export default class DataRoute extends Route {
  @service declare finStandaloneService: FinStandaloneService;

  async beforeModel() {
    if (taskFor(this.finStandaloneService.loadZendeskConfig).isRunning) {
      await taskFor(this.finStandaloneService.loadZendeskConfig).lastRunning!;
    } else {
      await taskFor(this.finStandaloneService.loadZendeskConfig).perform();
    }
  }
}
