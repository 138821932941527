/* RESPONSIBLE TEAM: team-tickets-1 */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import RSVP from 'rsvp';
import { inject as service } from '@ember/service';

export default class AutomaticAwayModeRoute extends IntercomRoute {
  @service appService;
  @service permissionsService;
  @service store;

  beforeModel(transition) {
    return this.permissionsService.ensurePermissionWhenTransitioning(
      'can_manage_auto_away_mode',
      transition,
    );
  }

  model(_) {
    if (!this.appService.app.canUseTeammateStatusAutoAwayMode) {
      this.transitionTo('apps.app.settings.workspace.general');
    }

    return RSVP.hash({
      autoAwaySetting: this.store.queryRecord('auto-away-setting', {
        app_id: this.appService.app.id_code,
      }),
    });
  }
}
