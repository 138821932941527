/* RESPONSIBLE TEAM: team-product-exploration */

import type Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import SettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';
import Admin from 'embercom/models/admin';
import { hash } from 'rsvp';
import { getAdminProfile } from 'embercom/lib/admins/admin-profiles';

export default class DetailsRoute extends SettingsRoute {
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare appService: $TSFixMe;

  analytics = {
    section: 'settings',
    place: 'personal.details',
  };

  get app() {
    return this.appService.app;
  }

  get currentAdmin() {
    return this.app.currentAdmin;
  }

  titleToken(model: any) {
    return model.get('name');
  }

  model() {
    let admin = Admin.peekAndMaybeLoad(this.store, this.currentAdmin.id);
    if (!admin?.location_data) {
      admin = this.store.findRecord('admin', this.currentAdmin.id) as unknown as Admin;
    }

    this.trackProfileAccess(this.currentAdmin.id);
    return admin;
  }

  async afterModel(model: any) {
    if (model.get('isOperator')) {
      this.replaceWith('apps.app.automation.settings');
      return;
    }
    let socialAccounts = await this.store.query('social-account', { admin_id: model.get('id') });
    model.set('socialAccounts', socialAccounts);

    let profile = await getAdminProfile(this.store, this.app.id, model.get('id'));
    model.set('profile', profile);

    return hash({
      socialAccounts,
      profile,
    });
  }

  trackProfileAccess(adminId: string) {
    this.intercomEventService.trackEvent('activity-profile-visited', {
      admin_accessed_by_id: adminId,
      admin_accessed_id: adminId,
    });
  }
}
