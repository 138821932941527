/* RESPONSIBLE TEAM: team-ai-agent */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';

export default class AppsAppSettingsAiAutomationExternalAiRoute extends Route {
  @service declare router: RouterService;
  @service declare permissionsService: $TSFixMe;

  beforeModel(transition: Transition) {
    let app = this.modelFor('apps.app') as { canUseFeature: (feature: string) => boolean };

    if (!app.canUseFeature('answerbot-ai-global-opt-out')) {
      return this.router.transitionTo('apps.app.settings');
    }

    return this.permissionsService.ensureMultiplePermissionsWhenTransitioning(
      ['can_access_workspace_settings', 'can_create_and_edit_bots'],
      transition,
    );
  }
}
