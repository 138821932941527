/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-controller-access-in-routes */
import { inject as service } from '@ember/service';
import ModelCacheHelper from 'embercom/lib/model-cache/helper';
import { hash } from 'rsvp';
import SettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';

export default SettingsRoute.extend({
  store: service(),
  analytics: {
    section: 'settings',
    place: 'teams',
  },
  titleToken: 'Teams',
  modelDataCacheService: service(),
  appService: service(),
  permissionsService: service(),
  intercomConfirmService: service(),

  model() {
    let app = this.modelFor('apps.app');
    if (ModelCacheHelper.isDisabled()) {
      this.store.findRecord('app', app.get('id'));
    } else {
      this.modelDataCacheService.fetch('app', app.get('id'));
    }
    return hash({
      schedules: this.store.findAll('office-hours-schedule'),
      teamSettings: this.store.findAll('team-setting'),
    });
  },

  beforeModel(transition) {
    // the settings navigation menu has teams & roles combined and navigates to this route (apps.app.settings.teams)
    // so if the current teammate doesn't have access to teams, then transition straight to roles
    if (!this.canManageTeams() && !this.canManageRoles()) {
      this.permissionsService.cancelTransitionAndShowPermissionModal(
        'can_manage_teams',
        transition,
      );
    } else if (!this.canManageTeams()) {
      return this.transitionTo('apps.app.settings.workspace.teammates', {
        queryParams: { tab: 'roles' },
      });
    }
    return this._super(...arguments);
  },

  canManageTeams() {
    return this.permissionsService.currentAdminCan('can_manage_teams');
  },

  canManageRoles() {
    return this.permissionsService.currentAdminCan('can_manage_teammates');
  },

  afterModel() {
    let app = this.modelFor('apps.app');
    return this.modelDataCacheService.awaitRefreshedModel('app', app.get('id'));
  },

  _deleteOrRollback(model) {
    if (model.get('isNew')) {
      model.deleteRecord();
    } else {
      model.rollbackAttributes();
    }
  },

  _revertTeamsState() {
    let dirtyTeams = this.get('controller.dirtyTeams');
    let dirtyTeamSettings = this.get('controller.dirtyTeamSettings');

    dirtyTeams.forEach(this._deleteOrRollback);
    dirtyTeamSettings.forEach(this._deleteOrRollback);
  },

  actions: {
    async error(e) {
      if (e.responseJSON && e.responseJSON.test_app) {
        let options = {
          title: 'Switching to production',
          body: 'Teammate settings aren’t available on your test version of Intercom. Would you like to switch to your production version?',
          confirmButtonText: 'Switch to production',
        };
        let isConfirmed = await this.intercomConfirmService.confirm(options);
        if (!isConfirmed) {
          return;
        }
        let parentAppId = e.responseJSON.parent_app_id;
        window.location = `/a/apps/${parentAppId}/settings/teams/`;
      }
    },
    willTransition(transition) {
      if (this.controller.hasUnsavedChanges()) {
        if (window.confirm('Warning: You have unsaved changes. Do you want to discard them?')) {
          this._revertTeamsState();
        } else {
          transition.abort();
        }
      }
    },
  },
});
